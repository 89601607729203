<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1"
        >&copy; {{ new Date().getFullYear() }}
        <a href="https://zigatext.com" target="_blank">Zigatext </a></span
      >
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://kemonai.com">Kemonai </a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
};
</script>
