<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img src="img/avatars/6.jpg" class="c-avatar-img" />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader>
    <CDropdownItem>
      <CIcon name="cil-bell" /> Updates
      <CBadge color="info" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-envelope-open" /> Messages
      <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>

    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Settings</strong>
    </CDropdownHeader>
    <CDropdownItem> <CIcon name="cil-user" /> Profile </CDropdownItem>
    <CDropdownItem> <CIcon name="cil-settings" /> Settings </CDropdownItem>

    <CDropdownDivider />
    <a
      class="anchor-tag"
      href="https://app.zigatext.com/account/login"
      target="blank"
      ><CIcon name="cil-user" style="margin-left: 20px" /> User Portal
    </a>

    <CDropdownItem @click="handleLogout" style="margin-top: 10px">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheHeaderDropdownAccnt',
  data() {
    return {
      itemsCount: 42,
    };
  },

  computed: {
    ...mapGetters('user', ['user']),
  },

  methods: {
    ...mapActions('auth', ['logout']),

    handleLogout() {
      this.logout();
      document.body.classList.remove(
        'aside-menu-lg-show',
        'header-fixed',
        'sidebar-fixed'
      );

      window.location.reload();
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
.anchor-tag {
  color: black;
}
</style>
