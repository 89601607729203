<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="toggleSidebar({ device: 'toggleSidebarMobile' })"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="toggleSidebar({ device: 'toggleSidebarDesktop' })"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <img
        src="../assets/images/zigatext_logo.png"
        class="header-image"
        alt="ziga text logo"
      />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard"> Dashboard </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <CHeaderNavItem class="px-3 c-d-legacy-none">
        <button @click="toggleTheme" class="c-header-nav-btn">
          <CIcon v-if="darkMode" name="cil-sun" />
          <CIcon v-else name="cil-moon" />
        </button>
      </CHeaderNavItem>
      <TheHeaderDropdownNotif />
      <TheHeaderDropdownTasks />
      <TheHeaderDropdownMssgs />
      <TheHeaderDropdownAccnt />
      <CHeaderNavItem class="px-3">
        <button class="c-header-nav-btn" @click="toggleAction('asideShow')">
          <CIcon size="lg" name="cil-applications-settings" class="mr-2" />
        </button>
      </CHeaderNavItem>
    </CHeaderNav>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt';
import TheHeaderDropdownNotif from './TheHeaderDropdownNotif';
import TheHeaderDropdownTasks from './TheHeaderDropdownTasks';
import TheHeaderDropdownMssgs from './TheHeaderDropdownMssgs';
import { mapState, mapActions } from 'vuex';
import { StorageService } from '@/services/storage.service';

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    TheHeaderDropdownNotif,
    TheHeaderDropdownTasks,
    TheHeaderDropdownMssgs,
  },
  created() {
    this.getUserTheme();
  },
  computed: {
    ...mapState('coreuiStore', ['darkMode']),
  },
  methods: {
    ...mapActions('coreuiStore', [
      'toggleSidebar',
      'toggleAction',
      'setAction',
    ]),

    toggleTheme() {
      this.toggleAction('darkMode');
      StorageService.saveItem('appTheme', this.darkMode);
    },

    getUserTheme() {
      const userTheme = StorageService.getItem('appTheme');

      if (userTheme === null || userTheme === undefined) return;

      this.setAction(['darkMode', userTheme == 'true']);
    },
  },
};
</script>

<style scoped>
.header-image {
  height: 50px;
  width: 150px;
}
</style>
